<template>
  <div class="shop">
    <div class="search">
      <div class="s-items" v-if="!Mobile">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="|"
          start-placeholder="Start date"
          end-placeholder="End date"
          :size="large"
          format="DD-MMM-YYYY"
          :inline="Mobile"
        />
      </div>
      <div class="s-items" v-if="Mobile">
        <el-date-picker
          v-model="date[0]"
          type="date"
          :size="large"
          format="DD-MMM-YYYY"
        />
      </div>
      <div class="s-items" v-if="Mobile">
        <el-date-picker
          v-model="date[1]"
          type="date"
          :size="large"
          format="DD-MMM-YYYY"
        />
      </div>
      <div class="s-items">
        <el-input-number
          :size="large"
          v-model="guests"
          :min="1"
          :max="10"
          class="custom-number-input"
          style="width: 100%"
        />
      </div>
      <div class="s-items">
        <el-button class="button" size="large" type="primary" @click="search()"
          >Search</el-button
        >
      </div>
    </div>
    <div class="results" v-if="rooms && rooms.length > 0 && loading == false">
      <div class="filters-sm" v-if="Mobile">
        <div class="f-item">
          <el-button size="large" type="default">Filters</el-button>
        </div>
        <div class="f-item">
          <el-select
            v-model="sort"
            placeholder="Select Sort Order"
            size="large"
            style="width: 180px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="filters" v-if="!Mobile">
        <div class="amount">
          <div class="header">Price</div>
          <div class="slider">
            <div class="range">${{ price[0] }} - ${{ price[1] }}</div>
            <el-slider
              v-model="price"
              range
              :step="step"
              :min="minPrice"
              :max="maxPrice"
              :range-start-label="minPrice"
              :range-end-label="maxPrice"
              style="margin: 0 0 10px 20px; width: 150px"
            />
          </div>
        </div>
        <div class="amenities">
          <div class="header">Amenites</div>
          <el-checkbox-group class="list" v-model="selectedAmenities">
            <el-checkbox
              v-for="(amenity, index) in amenities"
              :key="index"
              :label="amenity.name"
              :value="amenity.code"
            />
          </el-checkbox-group>
        </div>
      </div>
      <div class="rooms">
        <div class="cards" v-for="(room, index) in filteredRooms" :key="index">
          <div class="images">
            <el-carousel
              indicator-position="inside"
              height="250px"
              :autoplay="false"
              style="width: 100%"
            >
              <el-carousel-item
                v-for="(image, imgIndex) in room.imageURLs"
                :key="imgIndex"
              >
                <img
                  :src="image"
                  alt="Image"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px 10px 0 0;
                  "
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="content">
            <div class="title">{{ room.title }}</div>
            <div class="desc">{{ room.desc }}</div>
            <div class="availability">
              <div class="price">{{ room.price }}</div>
              <div class="book">
                <el-button size="large" type="primary" @click="goToNextStep"
                  >Book Now</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="rooms.length == 0">
      <div v-if="(loading = false)" class="error">No Availability</div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Search",
  props: {
    Mobile: String,
  },
  data() {
    return {
      selectedRange: null,
      options: [
        {
          label: "Sort : High to Low",
          value: "high",
        },
        {
          label: "Sort : Low to High",
          value: "low",
        },
      ],
      sort: "low",
      large: "large",
      date: null,
      checkInDate: null,
      checkOutDate: null,
      guests: 1,
      rooms: [],
      data: null,
      loading: false,
      selectedAmenities: [],
      maxPrice: 1000,
      minPrice: 0,
      price: [0, 1000], // Initialize price as an array with two values
      step: 1,
    };
  },

  mounted() {
    this.setDefaultDates();
    this.search();
  },

  computed: {
    filteredRooms() {
      let filteredRooms = this.rooms;

      if (this.selectedAmenities.length > 0) {
        return this.rooms.filter(
          (room) =>
            Array.isArray(room.amenities) &&
            this.selectedAmenities.every((y) =>
              room.amenities.some((amenity) => amenity.code === y)
            )
        );
      }

      if (this.price.length === 2) {
        const [minp, maxp] = this.price;
        filteredRooms = filteredRooms.filter((room) => {
          const roomPrice = parseFloat(
            room.price.replace("$", "").replace(",", "")
          );
          return roomPrice >= minp && roomPrice <= maxp;
        });
      }
      return filteredRooms;
    },
  },

  methods: {
    onRangeSelect(range) {
      this.selectedRange = range; // Update selected range
      console.log("Selected Range:", range);
    },

    setDefaultDates() {
      const currentDate = dayjs(); // Get the current date and time
      this.checkInDate = currentDate.format("YYYY-MM-DD");
      this.checkOutDate = currentDate.add(1, "day").format("YYYY-MM-DD");
      this.date = [this.checkInDate, this.checkOutDate];
    },

    formattedDate(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },

    async search() {
      // let checkInDate = dayjs(this.date[0]).format("YYYY-MM-DD");
      // let checkOutDate = dayjs(this.date[1]).format("YYYY-MM-DD");
      // let guests = this.guests;
      this.loading = true;
      this.data = await this.$bookingService();
      this.rooms = this.data.rooms;
      this.loading = false;
      this.getAmenities();
      console.log(this.loading);
    },

    getAmenities() {
      const allAmenities = this.rooms
        .filter((x) => Array.isArray(x.amenities))
        .flatMap((x) => x.amenities);

      this.amenities = allAmenities.filter(
        (amenity, index, self) =>
          self.findIndex((a) => a.code === amenity.code) === index
      );
      console.log(this.amenities);
    },

    goToNextStep() {
      this.$emit("next-step");
      console.log("Rohit"); // Emit the event to parent component
    },
  },
};
</script>

<style scoped>
.shop {
  display: flex;
  flex-direction: column;
  margin: auto 10%;
}

.search {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin: 0 auto;
  border: 1px solid #eaeaea;
  border-radius: 20px;
}

.s-items {
  padding: 10px;
}

.button {
  border-radius: 8px;
  font-weight: bold;
  width: 100%;
}

.results {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  margin: 20px 0px;
}

@media (max-width: 768px) {
  .results {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .search {
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
  }
}

.filters-sm {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 0 20px 0;
}

.f-item {
  padding: 0px 0px 0px 10px;
}

.filters {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  margin: 0 20px;
  height: max-content;
}

.header {
  font-weight: bold;
  padding: 20px 0px 10px;
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.amenities {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.rooms {
  display: flex;
  flex-direction: column;
}

.cards {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 15px 0px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

/* @media (max-width: 768px) {
  .cards {
    flex-direction: row;
  }
} */

.content {
  margin: 0px;
}

.title {
  font-size: 16px;
  padding: 5px 10px;
  font-weight: bold;
  text-align: left;
}
.desc {
  padding: 0px 10px;
  font-size: 14px;
  text-align: left;
}

.availability {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
  align-items: center;
}

.price {
  font-size: 16px;
  padding: 5px 0;
  font-weight: bold;
  text-align: left;
}
</style>
