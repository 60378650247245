<template>
  <!-- <div class="header">
    <div class="logo">
      <img src="@/assets/logo.png" style="width: 40px; height: 40px" />
    </div>
    <div class="nav" v-if="step === 2">
      <el-button size="large" @click="goBack()" type="primary"
        >Back to Rooms</el-button
      >
    </div>
  </div> -->

  <Shop v-if="step === 1" @next-step="nextStep" :Mobile="isMobile" />
  <Checkout
    v-if="step === 2"
    @next-step="nextStep"
    :Mobile="isMobile"
    @prev-step="goBack"
  />
</template>

<script>
import Shop from "@/views/booking/Shop.vue";
import Checkout from "@/views/booking/Checkout.vue";

export default {
  name: "Booking",
  components: { Shop, Checkout },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  data() {
    return {
      step: 1,
      isMobile: false,
    };
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 800;
      let width = window.innerWidth;
      console.log(width, this.isMobile);
    },

    nextStep() {
      if (this.step < 3) {
        this.step++;
        console.log(this.step);
      }
    },

    goBack() {
      if (this.step > 1) {
        this.step--;
      }
    },
  },
};
</script>

<style>
.header {
  padding: 20px;
}

body {
  background-color: #ffffff;
  color: #000000;
}
</style>
